import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: "header-menu align-items-stretch",
  "data-kt-drawer": "true",
  "data-kt-drawer-name": "header-menu",
  "data-kt-drawer-activate": "{default: true, lg: false}",
  "data-kt-drawer-overlay": "true",
  "data-kt-drawer-width": "{default:'200px', '300px': '250px'}",
  "data-kt-drawer-direction": "end",
  "data-kt-drawer-toggle": "#kt_header_menu_mobile_toggle",
  "data-kt-place": "true",
  "data-kt-place-mode": "prepend",
  "data-kt-place-parent": "{default: '#kt_body', lg: '#kt_header_nav'}"
}
const _hoisted_2 = {
  class: "menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch",
  id: "#kt_header_menu",
  "data-kt-menu": "true"
}
const _hoisted_3 = {
  key: 0,
  class: "menu-item me-lg-1"
}
const _hoisted_4 = { class: "menu-title" }
const _hoisted_5 = {
  key: 1,
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-start",
  class: "menu-item menu-lg-down-accordion me-lg-1"
}
const _hoisted_6 = { class: "menu-title" }
const _hoisted_7 = { class: "menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-rounded-0 py-lg-4 w-lg-225px" }
const _hoisted_8 = {
  key: 0,
  "data-kt-menu-trigger": "{default:'click', lg: 'hover'}",
  "data-kt-menu-placement": "right-start",
  class: "menu-item menu-lg-down-accordion"
}
const _hoisted_9 = { class: "menu-icon" }
const _hoisted_10 = {
  key: 1,
  class: "svg-icon svg-icon-2"
}
const _hoisted_11 = { class: "menu-title" }
const _hoisted_12 = { class: "menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg py-lg-4 w-lg-225px" }
const _hoisted_13 = {
  key: 0,
  "data-kt-menu-trigger": "{default:'click', lg: 'hover'}",
  "data-kt-menu-placement": "right-start",
  class: "menu-item menu-lg-down-accordion"
}
const _hoisted_14 = { class: "menu-title" }
const _hoisted_15 = { class: "menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg py-lg-4 w-lg-225px" }
const _hoisted_16 = { class: "menu-title" }
const _hoisted_17 = {
  key: 1,
  class: "menu-item"
}
const _hoisted_18 = { class: "menu-title" }
const _hoisted_19 = {
  key: 1,
  class: "menu-item"
}
const _hoisted_20 = { class: "menu-icon" }
const _hoisted_21 = { class: "svg-icon svg-icon-2" }
const _hoisted_22 = { class: "menu-title" }
const _hoisted_23 = {
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-start",
  class: "menu-item menu-lg-down-accordion me-lg-1"
}
const _hoisted_24 = { class: "menu-link py-3" }
const _hoisted_25 = { class: "menu-title" }
const _hoisted_26 = { class: "menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-rounded-0 py-lg-4 w-lg-225px" }
const _hoisted_27 = { class: "menu-item" }
const _hoisted_28 = {
  class: "menu-link py-3",
  href: "https://preview.keenthemes.com/metronic8/vue/docs/#/utilities"
}
const _hoisted_29 = { class: "menu-icon" }
const _hoisted_30 = {
  key: 0,
  class: "bi bi-box fs-3"
}
const _hoisted_31 = {
  key: 1,
  class: "svg-icon svg-icon-2"
}
const _hoisted_32 = { class: "menu-title" }
const _hoisted_33 = { class: "menu-item" }
const _hoisted_34 = {
  class: "menu-link py-3",
  href: "https://preview.keenthemes.com/metronic8/vue/docs/#/doc-overview"
}
const _hoisted_35 = { class: "menu-icon" }
const _hoisted_36 = {
  key: 0,
  class: "bi bi-card-text fs-3"
}
const _hoisted_37 = {
  key: 1,
  class: "svg-icon svg-icon-2"
}
const _hoisted_38 = { class: "menu-title" }
const _hoisted_39 = { class: "menu-item" }
const _hoisted_40 = {
  class: "menu-link py-3",
  href: "https://preview.keenthemes.com/metronic8/vue/docs/#/changelog"
}
const _hoisted_41 = { class: "menu-icon" }
const _hoisted_42 = {
  key: 0,
  class: "bi bi-journal-code fs-3"
}
const _hoisted_43 = {
  key: 1,
  class: "svg-icon svg-icon-2"
}
const _hoisted_44 = { class: "menu-title" }
const _hoisted_45 = {
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-start",
  class: "menu-item menu-lg-down-accordion me-lg-1"
}
const _hoisted_46 = { class: "menu-link py-3" }
const _hoisted_47 = { class: "menu-title" }
const _hoisted_48 = {
  class: "menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown w-100 w-lg-600px p-5 p-lg-5",
  style: {}
}
const _hoisted_49 = {
  class: "row",
  "data-kt-menu-dismiss": "true"
}
const _hoisted_50 = { class: "col-lg-4 border-left-lg-1" }
const _hoisted_51 = { class: "menu-inline menu-column menu-active-bg" }
const _hoisted_52 = { class: "menu-item" }
const _hoisted_53 = {
  href: "#",
  class: "menu-link"
}
const _hoisted_54 = { class: "menu-title" }
const _hoisted_55 = { class: "menu-item" }
const _hoisted_56 = {
  href: "#",
  class: "menu-link"
}
const _hoisted_57 = { class: "menu-title" }
const _hoisted_58 = { class: "menu-item" }
const _hoisted_59 = {
  href: "#",
  class: "menu-link"
}
const _hoisted_60 = { class: "menu-title" }
const _hoisted_61 = { class: "menu-item" }
const _hoisted_62 = {
  href: "#",
  class: "menu-link"
}
const _hoisted_63 = { class: "menu-title" }
const _hoisted_64 = { class: "menu-item" }
const _hoisted_65 = {
  href: "#",
  class: "menu-link"
}
const _hoisted_66 = { class: "menu-title" }
const _hoisted_67 = { class: "col-lg-4 border-left-lg-1" }
const _hoisted_68 = { class: "menu-inline menu-column menu-active-bg" }
const _hoisted_69 = { class: "menu-item" }
const _hoisted_70 = {
  href: "#",
  class: "menu-link"
}
const _hoisted_71 = { class: "menu-title" }
const _hoisted_72 = { class: "menu-item" }
const _hoisted_73 = {
  href: "#",
  class: "menu-link"
}
const _hoisted_74 = { class: "menu-title" }
const _hoisted_75 = { class: "menu-item" }
const _hoisted_76 = {
  href: "#",
  class: "menu-link"
}
const _hoisted_77 = { class: "menu-title" }
const _hoisted_78 = { class: "menu-item" }
const _hoisted_79 = {
  href: "#",
  class: "menu-link"
}
const _hoisted_80 = { class: "menu-title" }
const _hoisted_81 = { class: "menu-item" }
const _hoisted_82 = {
  href: "#",
  class: "menu-link"
}
const _hoisted_83 = { class: "menu-title" }
const _hoisted_84 = { class: "col-lg-4 border-left-lg-1" }
const _hoisted_85 = { class: "menu-inline menu-column menu-active-bg" }
const _hoisted_86 = { class: "menu-item" }
const _hoisted_87 = {
  href: "#",
  class: "menu-link"
}
const _hoisted_88 = { class: "menu-title" }
const _hoisted_89 = { class: "menu-item" }
const _hoisted_90 = {
  href: "#",
  class: "menu-link"
}
const _hoisted_91 = { class: "menu-title" }
const _hoisted_92 = { class: "menu-item" }
const _hoisted_93 = {
  href: "#",
  class: "menu-link"
}
const _hoisted_94 = { class: "menu-title" }
const _hoisted_95 = { class: "menu-item" }
const _hoisted_96 = {
  href: "#",
  class: "menu-link"
}
const _hoisted_97 = { class: "menu-title" }
const _hoisted_98 = { class: "menu-item" }
const _hoisted_99 = {
  href: "#",
  class: "menu-link"
}
const _hoisted_100 = { class: "menu-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.MainMenuConfig, (item, i) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
          (!item.heading)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(item.pages, (menuItem, j) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: j }, [
                  (menuItem.heading)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createVNode(_component_router_link, {
                          class: "menu-link",
                          to: menuItem.route,
                          "active-class": "active"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.translate(menuItem.heading)), 1)
                          ]),
                          _: 2
                        }, 1032, ["to"])
                      ]))
                    : _createCommentVNode("", true)
                ], 64))
              }), 128))
            : _createCommentVNode("", true),
          (item.heading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("span", {
                  class: _normalizeClass(["menu-link py-3", { active: _ctx.hasActiveChildren(item.route) }])
                }, [
                  _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.translate(item.heading)), 1),
                  _cache[0] || (_cache[0] = _createElementVNode("span", { class: "menu-arrow d-lg-none" }, null, -1))
                ], 2),
                _createElementVNode("div", _hoisted_7, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.pages, (menuItem, j) => {
                    return (_openBlock(), _createElementBlock(_Fragment, { key: j }, [
                      (menuItem.sectionTitle)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                            _createElementVNode("span", {
                              class: _normalizeClass(["menu-link py-3", { active: _ctx.hasActiveChildren(menuItem.route) }])
                            }, [
                              _createElementVNode("span", _hoisted_9, [
                                (_ctx.headerMenuIcons === 'font')
                                  ? (_openBlock(), _createElementBlock("i", {
                                      key: 0,
                                      class: _normalizeClass([menuItem.fontIcon, "bi fs-3"])
                                    }, null, 2))
                                  : _createCommentVNode("", true),
                                (_ctx.headerMenuIcons === 'svg')
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
                                      _createVNode(_component_inline_svg, {
                                        src: menuItem.svgIcon
                                      }, null, 8, ["src"])
                                    ]))
                                  : _createCommentVNode("", true)
                              ]),
                              _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.translate(menuItem.sectionTitle)), 1),
                              _cache[1] || (_cache[1] = _createElementVNode("span", { class: "menu-arrow" }, null, -1))
                            ], 2),
                            _createElementVNode("div", _hoisted_12, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuItem.sub, (menuItem1, k) => {
                                return (_openBlock(), _createElementBlock(_Fragment, { key: k }, [
                                  (menuItem1.sectionTitle)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                        _createElementVNode("span", {
                                          class: _normalizeClass(["menu-link py-3", { active: _ctx.hasActiveChildren(menuItem1.route) }])
                                        }, [
                                          _cache[2] || (_cache[2] = _createElementVNode("span", { class: "menu-bullet" }, [
                                            _createElementVNode("span", { class: "bullet bullet-dot" })
                                          ], -1)),
                                          _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.translate(menuItem1.sectionTitle)), 1),
                                          _cache[3] || (_cache[3] = _createElementVNode("span", { class: "menu-arrow" }, null, -1))
                                        ], 2),
                                        _createElementVNode("div", _hoisted_15, [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuItem1.sub, (menuItem2, l) => {
                                            return (_openBlock(), _createElementBlock("div", {
                                              key: l,
                                              class: "menu-item"
                                            }, [
                                              _createVNode(_component_router_link, {
                                                class: "menu-link py-3",
                                                "active-class": "active",
                                                to: menuItem2.route
                                              }, {
                                                default: _withCtx(() => [
                                                  _cache[4] || (_cache[4] = _createElementVNode("span", { class: "menu-bullet" }, [
                                                    _createElementVNode("span", { class: "bullet bullet-dot" })
                                                  ], -1)),
                                                  _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.translate(menuItem2.heading)), 1)
                                                ]),
                                                _: 2
                                              }, 1032, ["to"])
                                            ]))
                                          }), 128))
                                        ])
                                      ]))
                                    : _createCommentVNode("", true),
                                  (menuItem1.heading)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                        _createVNode(_component_router_link, {
                                          class: "menu-link",
                                          "active-class": "active",
                                          to: menuItem1.route
                                        }, {
                                          default: _withCtx(() => [
                                            _cache[5] || (_cache[5] = _createElementVNode("span", { class: "menu-bullet" }, [
                                              _createElementVNode("span", { class: "bullet bullet-dot" })
                                            ], -1)),
                                            _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.translate(menuItem1.heading)), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["to"])
                                      ]))
                                    : _createCommentVNode("", true)
                                ], 64))
                              }), 128))
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      (menuItem.heading)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                            _createVNode(_component_router_link, {
                              class: "menu-link",
                              "active-class": "active",
                              to: menuItem.route
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", _hoisted_20, [
                                  _createElementVNode("span", _hoisted_21, [
                                    _createVNode(_component_inline_svg, { src: "media/icons/duotune/layouts/lay009.svg" })
                                  ])
                                ]),
                                _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.translate(menuItem.heading)), 1)
                              ]),
                              _: 2
                            }, 1032, ["to"])
                          ]))
                        : _createCommentVNode("", true)
                    ], 64))
                  }), 128))
                ])
              ]))
            : _createCommentVNode("", true)
        ], 64))
      }), 128)),
      _createElementVNode("div", _hoisted_23, [
        _createElementVNode("span", _hoisted_24, [
          _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.translate("resources")), 1),
          _cache[6] || (_cache[6] = _createElementVNode("span", { class: "menu-arrow d-lg-none" }, null, -1))
        ]),
        _createElementVNode("div", _hoisted_26, [
          _createElementVNode("div", _hoisted_27, [
            _createElementVNode("a", _hoisted_28, [
              _createElementVNode("span", _hoisted_29, [
                (_ctx.headerMenuIcons === 'font')
                  ? (_openBlock(), _createElementBlock("i", _hoisted_30))
                  : _createCommentVNode("", true),
                (_ctx.headerMenuIcons === 'svg')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_31, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen002.svg" })
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("span", _hoisted_32, _toDisplayString(_ctx.translate("components")), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_33, [
            _createElementVNode("a", _hoisted_34, [
              _createElementVNode("span", _hoisted_35, [
                (_ctx.headerMenuIcons === 'font')
                  ? (_openBlock(), _createElementBlock("i", _hoisted_36))
                  : (_ctx.headerMenuIcons === 'svg')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_37, [
                        _createVNode(_component_inline_svg, { src: "media/icons/duotune/abstract/abs027.svg" })
                      ]))
                    : _createCommentVNode("", true)
              ]),
              _createElementVNode("span", _hoisted_38, _toDisplayString(_ctx.translate("documentation")), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_39, [
            _createElementVNode("a", _hoisted_40, [
              _createElementVNode("span", _hoisted_41, [
                (_ctx.headerMenuIcons === 'font')
                  ? (_openBlock(), _createElementBlock("i", _hoisted_42))
                  : (_ctx.headerMenuIcons === 'svg')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_43, [
                        _createVNode(_component_inline_svg, { src: "media/icons/duotune/coding/cod003.svg" })
                      ]))
                    : _createCommentVNode("", true)
              ]),
              _createElementVNode("span", _hoisted_44, _toDisplayString(_ctx.translate("changelog")) + " v" + _toDisplayString(_ctx.version), 1)
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_45, [
        _createElementVNode("span", _hoisted_46, [
          _createElementVNode("span", _hoisted_47, _toDisplayString(_ctx.translate("megaMenu")), 1),
          _cache[7] || (_cache[7] = _createElementVNode("span", { class: "menu-arrow d-lg-none" }, null, -1))
        ]),
        _createElementVNode("div", _hoisted_48, [
          _createElementVNode("div", _hoisted_49, [
            _createElementVNode("div", _hoisted_50, [
              _createElementVNode("div", _hoisted_51, [
                _createElementVNode("div", _hoisted_52, [
                  _createElementVNode("a", _hoisted_53, [
                    _cache[8] || (_cache[8] = _createElementVNode("span", { class: "menu-bullet" }, [
                      _createElementVNode("span", { class: "bullet bullet-dot" })
                    ], -1)),
                    _createElementVNode("span", _hoisted_54, _toDisplayString(_ctx.translate("exampleLink")), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_55, [
                  _createElementVNode("a", _hoisted_56, [
                    _cache[9] || (_cache[9] = _createElementVNode("span", { class: "menu-bullet" }, [
                      _createElementVNode("span", { class: "bullet bullet-dot" })
                    ], -1)),
                    _createElementVNode("span", _hoisted_57, _toDisplayString(_ctx.translate("exampleLink")), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_58, [
                  _createElementVNode("a", _hoisted_59, [
                    _cache[10] || (_cache[10] = _createElementVNode("span", { class: "menu-bullet" }, [
                      _createElementVNode("span", { class: "bullet bullet-dot" })
                    ], -1)),
                    _createElementVNode("span", _hoisted_60, _toDisplayString(_ctx.translate("exampleLink")), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_61, [
                  _createElementVNode("a", _hoisted_62, [
                    _cache[11] || (_cache[11] = _createElementVNode("span", { class: "menu-bullet" }, [
                      _createElementVNode("span", { class: "bullet bullet-dot" })
                    ], -1)),
                    _createElementVNode("span", _hoisted_63, _toDisplayString(_ctx.translate("exampleLink")), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_64, [
                  _createElementVNode("a", _hoisted_65, [
                    _cache[12] || (_cache[12] = _createElementVNode("span", { class: "menu-bullet" }, [
                      _createElementVNode("span", { class: "bullet bullet-dot" })
                    ], -1)),
                    _createElementVNode("span", _hoisted_66, _toDisplayString(_ctx.translate("exampleLink")), 1)
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_67, [
              _createElementVNode("div", _hoisted_68, [
                _createElementVNode("div", _hoisted_69, [
                  _createElementVNode("a", _hoisted_70, [
                    _cache[13] || (_cache[13] = _createElementVNode("span", { class: "menu-bullet" }, [
                      _createElementVNode("span", { class: "bullet bullet-dot" })
                    ], -1)),
                    _createElementVNode("span", _hoisted_71, _toDisplayString(_ctx.translate("exampleLink")), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_72, [
                  _createElementVNode("a", _hoisted_73, [
                    _cache[14] || (_cache[14] = _createElementVNode("span", { class: "menu-bullet" }, [
                      _createElementVNode("span", { class: "bullet bullet-dot" })
                    ], -1)),
                    _createElementVNode("span", _hoisted_74, _toDisplayString(_ctx.translate("exampleLink")), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_75, [
                  _createElementVNode("a", _hoisted_76, [
                    _cache[15] || (_cache[15] = _createElementVNode("span", { class: "menu-bullet" }, [
                      _createElementVNode("span", { class: "bullet bullet-dot" })
                    ], -1)),
                    _createElementVNode("span", _hoisted_77, _toDisplayString(_ctx.translate("exampleLink")), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_78, [
                  _createElementVNode("a", _hoisted_79, [
                    _cache[16] || (_cache[16] = _createElementVNode("span", { class: "menu-bullet" }, [
                      _createElementVNode("span", { class: "bullet bullet-dot" })
                    ], -1)),
                    _createElementVNode("span", _hoisted_80, _toDisplayString(_ctx.translate("exampleLink")), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_81, [
                  _createElementVNode("a", _hoisted_82, [
                    _cache[17] || (_cache[17] = _createElementVNode("span", { class: "menu-bullet" }, [
                      _createElementVNode("span", { class: "bullet bullet-dot" })
                    ], -1)),
                    _createElementVNode("span", _hoisted_83, _toDisplayString(_ctx.translate("exampleLink")), 1)
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_84, [
              _createElementVNode("div", _hoisted_85, [
                _createElementVNode("div", _hoisted_86, [
                  _createElementVNode("a", _hoisted_87, [
                    _cache[18] || (_cache[18] = _createElementVNode("span", { class: "menu-bullet" }, [
                      _createElementVNode("span", { class: "bullet bullet-dot" })
                    ], -1)),
                    _createElementVNode("span", _hoisted_88, _toDisplayString(_ctx.translate("exampleLink")), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_89, [
                  _createElementVNode("a", _hoisted_90, [
                    _cache[19] || (_cache[19] = _createElementVNode("span", { class: "menu-bullet" }, [
                      _createElementVNode("span", { class: "bullet bullet-dot" })
                    ], -1)),
                    _createElementVNode("span", _hoisted_91, _toDisplayString(_ctx.translate("exampleLink")), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_92, [
                  _createElementVNode("a", _hoisted_93, [
                    _cache[20] || (_cache[20] = _createElementVNode("span", { class: "menu-bullet" }, [
                      _createElementVNode("span", { class: "bullet bullet-dot" })
                    ], -1)),
                    _createElementVNode("span", _hoisted_94, _toDisplayString(_ctx.translate("exampleLink")), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_95, [
                  _createElementVNode("a", _hoisted_96, [
                    _cache[21] || (_cache[21] = _createElementVNode("span", { class: "menu-bullet" }, [
                      _createElementVNode("span", { class: "bullet bullet-dot" })
                    ], -1)),
                    _createElementVNode("span", _hoisted_97, _toDisplayString(_ctx.translate("exampleLink")), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_98, [
                  _createElementVNode("a", _hoisted_99, [
                    _cache[22] || (_cache[22] = _createElementVNode("span", { class: "menu-bullet" }, [
                      _createElementVNode("span", { class: "bullet bullet-dot" })
                    ], -1)),
                    _createElementVNode("span", _hoisted_100, _toDisplayString(_ctx.translate("exampleLink")), 1)
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}