
import { defineComponent, ref } from "vue";
import Results from "@/layout/header/partials/search/Results.vue";
import Main from "@/layout/header/partials/search/Main.vue";
import Empty from "@/layout/header/partials/search/Empty.vue";
import MenuComponent from "@/components/menu/MenuComponent.vue";

export default defineComponent({
  name: "kt-search",
  components: {
    Results,
    Main,
    Empty,
    MenuComponent,
  },
  setup() {
    const search = ref<string>("");
    const state = ref<
      "main" | "empty" | "advanced-options" | "preferences" | "results"
    >("main");
    const loading = ref<boolean>(false);
    const inputRef = ref<HTMLInputElement | null>(null);

    const searching = (e) => {
      // console.log(e.target.value);
      if (e.target.value.length > 1) {
        if (e.target.value.length > 5) {
          load("empty");
          return;
        }
        load("results");
      } else {
        load("main");
      }
    };

    const load = (current) => {
      loading.value = true;
      setTimeout(() => {
        state.value = current;
        loading.value = false;
      }, 1000);
    };

    const reset = () => {
      search.value = "";
      state.value = "main";
    };

    const setState = (curr) => {
      state.value = curr;
    };

    return {
      search,
      state,
      loading,
      searching,
      reset,
      inputRef,
      setState,
    };
  },
});
