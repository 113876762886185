import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  id: "kt_header_search",
  class: "d-flex align-items-stretch",
  "data-kt-menu-target": "#kt-search-menu",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-attach": "parent",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "bottom"
}
const _hoisted_2 = {
  class: "d-flex align-items-center",
  id: "kt_header_search_toggle"
}
const _hoisted_3 = { class: "btn btn-icon btn-active-light-primary" }
const _hoisted_4 = { class: "svg-icon svg-icon-1" }
const _hoisted_5 = {
  class: "menu menu-sub menu-sub-dropdown menu-column p-7 w-325px w-md-375px",
  "data-kt-menu": "true",
  id: "kt-search-menu"
}
const _hoisted_6 = {
  class: "w-100 position-relative mb-3",
  autocomplete: "off"
}
const _hoisted_7 = { class: "svg-icon svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 translate-middle-y ms-0" }
const _hoisted_8 = {
  key: 0,
  class: "position-absolute top-50 end-0 translate-middle-y lh-0 me-1"
}
const _hoisted_9 = { class: "svg-icon svg-icon-2 svg-icon-lg-1 me-0" }
const _hoisted_10 = { class: "position-absolute top-50 end-0 translate-middle-y" }
const _hoisted_11 = { class: "svg-icon svg-icon-1" }
const _hoisted_12 = { class: "svg-icon svg-icon-2" }
const _hoisted_13 = {
  key: 0,
  class: "pt-1"
}
const _hoisted_14 = { class: "d-flex justify-content-end" }
const _hoisted_15 = {
  key: 1,
  class: "pt-1"
}
const _hoisted_16 = { class: "d-flex justify-content-end pt-7" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Results = _resolveComponent("Results")!
  const _component_Main = _resolveComponent("Main")!
  const _component_Empty = _resolveComponent("Empty")!
  const _component_MenuComponent = _resolveComponent("MenuComponent")!

  return (_openBlock(), _createBlock(_component_MenuComponent, { "menu-selector": "#kt-search-menu" }, {
    toggle: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen021.svg" })
            ])
          ])
        ])
      ])
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", null, [
          _createElementVNode("form", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen021.svg" })
            ]),
            _withDirectives(_createElementVNode("input", {
              ref: "inputRef",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
              onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searching($event))),
              type: "text",
              class: "form-control form-control-flush ps-10",
              name: "search",
              placeholder: "Search..."
            }, null, 544), [
              [_vModelText, _ctx.search]
            ]),
            (_ctx.loading)
              ? (_openBlock(), _createElementBlock("span", _hoisted_8, _cache[7] || (_cache[7] = [
                  _createElementVNode("span", { class: "spinner-border h-15px w-15px align-middle text-gray-400" }, null, -1)
                ])))
              : _createCommentVNode("", true),
            _withDirectives(_createElementVNode("span", {
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.reset())),
              class: "btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0"
            }, [
              _createElementVNode("span", _hoisted_9, [
                _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr061.svg" })
              ])
            ], 512), [
              [_vShow, _ctx.search.length && !_ctx.loading]
            ]),
            _createElementVNode("div", _hoisted_10, [
              (!_ctx.search && !_ctx.loading)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.state = 'preferences')),
                    class: "btn btn-icon w-20px btn-sm btn-active-color-primary me-1",
                    "data-bs-toggle": "tooltip",
                    title: "Show search preferences"
                  }, [
                    _createElementVNode("span", _hoisted_11, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotune/coding/cod001.svg" })
                    ])
                  ]))
                : _createCommentVNode("", true),
              (!_ctx.search && !_ctx.loading)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.state = 'advanced-options')),
                    class: "btn btn-icon w-20px btn-sm btn-active-color-primary",
                    "data-bs-toggle": "tooltip",
                    title: "Show more search options"
                  }, [
                    _createElementVNode("span", _hoisted_12, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr072.svg" })
                    ])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _cache[8] || (_cache[8] = _createElementVNode("div", { class: "separator border-gray-200 mb-6" }, null, -1)),
          (_ctx.state === 'results')
            ? (_openBlock(), _createBlock(_component_Results, { key: 0 }))
            : (_ctx.state === 'main')
              ? (_openBlock(), _createBlock(_component_Main, { key: 1 }))
              : (_ctx.state === 'empty')
                ? (_openBlock(), _createBlock(_component_Empty, { key: 2 }))
                : _createCommentVNode("", true)
        ]),
        (_ctx.state === 'advanced-options')
          ? (_openBlock(), _createElementBlock("form", _hoisted_13, [
              _cache[10] || (_cache[10] = _createElementVNode("h3", { class: "fw-bold text-dark mb-7" }, "Advanced Search", -1)),
              _cache[11] || (_cache[11] = _createElementVNode("div", { class: "mb-5" }, [
                _createElementVNode("input", {
                  type: "text",
                  class: "form-control form-control-sm form-control-solid",
                  placeholder: "Contains the word",
                  name: "query"
                })
              ], -1)),
              _cache[12] || (_cache[12] = _createElementVNode("div", { class: "mb-5" }, [
                _createElementVNode("div", { class: "nav-group nav-group-fluid" }, [
                  _createElementVNode("label", null, [
                    _createElementVNode("input", {
                      type: "radio",
                      class: "btn-check",
                      name: "type",
                      value: "has",
                      checked: "checked"
                    }),
                    _createElementVNode("span", { class: "btn btn-sm btn-color-muted btn-active btn-active-primary" }, " All ")
                  ]),
                  _createElementVNode("label", null, [
                    _createElementVNode("input", {
                      type: "radio",
                      class: "btn-check",
                      name: "type",
                      value: "users"
                    }),
                    _createElementVNode("span", { class: "btn btn-sm btn-color-muted btn-active btn-active-primary px-4" }, " Users ")
                  ]),
                  _createElementVNode("label", null, [
                    _createElementVNode("input", {
                      type: "radio",
                      class: "btn-check",
                      name: "type",
                      value: "orders"
                    }),
                    _createElementVNode("span", { class: "btn btn-sm btn-color-muted btn-active btn-active-primary px-4" }, " Orders ")
                  ]),
                  _createElementVNode("label", null, [
                    _createElementVNode("input", {
                      type: "radio",
                      class: "btn-check",
                      name: "type",
                      value: "projects"
                    }),
                    _createElementVNode("span", { class: "btn btn-sm btn-color-muted btn-active btn-active-primary px-4" }, " Projects ")
                  ])
                ])
              ], -1)),
              _cache[13] || (_cache[13] = _createElementVNode("div", { class: "mb-5" }, [
                _createElementVNode("input", {
                  type: "text",
                  name: "assignedto",
                  class: "form-control form-control-sm form-control-solid",
                  placeholder: "Assigned to",
                  value: ""
                })
              ], -1)),
              _cache[14] || (_cache[14] = _createElementVNode("div", { class: "mb-5" }, [
                _createElementVNode("input", {
                  type: "text",
                  name: "collaborators",
                  class: "form-control form-control-sm form-control-solid",
                  placeholder: "Collaborators",
                  value: ""
                })
              ], -1)),
              _cache[15] || (_cache[15] = _createElementVNode("div", { class: "mb-5" }, [
                _createElementVNode("div", { class: "nav-group nav-group-fluid" }, [
                  _createElementVNode("label", null, [
                    _createElementVNode("input", {
                      type: "radio",
                      class: "btn-check",
                      name: "attachment",
                      value: "has",
                      checked: "checked"
                    }),
                    _createElementVNode("span", { class: "btn btn-sm btn-color-muted btn-active btn-active-primary" }, " Has attachment ")
                  ]),
                  _createElementVNode("label", null, [
                    _createElementVNode("input", {
                      type: "radio",
                      class: "btn-check",
                      name: "attachment",
                      value: "any"
                    }),
                    _createElementVNode("span", { class: "btn btn-sm btn-color-muted btn-active btn-active-primary px-4" }, " Any ")
                  ])
                ])
              ], -1)),
              _cache[16] || (_cache[16] = _createElementVNode("div", { class: "mb-5" }, [
                _createElementVNode("select", {
                  name: "timezone",
                  "aria-label": "Select a Timezone",
                  "data-control": "select2",
                  "data-placeholder": "date_period",
                  class: "form-select form-select-sm form-select-solid"
                }, [
                  _createElementVNode("option", { value: "next" }, "Within the next"),
                  _createElementVNode("option", { value: "last" }, "Within the last"),
                  _createElementVNode("option", { value: "between" }, "Between"),
                  _createElementVNode("option", { value: "on" }, "On")
                ])
              ], -1)),
              _cache[17] || (_cache[17] = _createElementVNode("div", { class: "row mb-8" }, [
                _createElementVNode("div", { class: "col-6" }, [
                  _createElementVNode("input", {
                    type: "number",
                    name: "date_number",
                    class: "form-control form-control-sm form-control-solid",
                    placeholder: "Lenght",
                    value: ""
                  })
                ]),
                _createElementVNode("div", { class: "col-6" }, [
                  _createElementVNode("select", {
                    name: "date_typer",
                    "aria-label": "Select a Timezone",
                    "data-control": "select2",
                    "data-placeholder": "Period",
                    class: "form-select form-select-sm form-select-solid"
                  }, [
                    _createElementVNode("option", { value: "days" }, "Days"),
                    _createElementVNode("option", { value: "weeks" }, "Weeks"),
                    _createElementVNode("option", { value: "months" }, "Months"),
                    _createElementVNode("option", { value: "years" }, "Years")
                  ])
                ])
              ], -1)),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("button", {
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.state = 'main')),
                  class: "btn btn-sm btn-white fw-bolder btn-active-light-primary me-2"
                }, " Cancel "),
                _cache[9] || (_cache[9] = _createElementVNode("a", {
                  href: "#",
                  class: "btn btn-sm fw-bolder btn-primary"
                }, "Search", -1))
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.state === 'preferences')
          ? (_openBlock(), _createElementBlock("form", _hoisted_15, [
              _cache[19] || (_cache[19] = _createElementVNode("h3", { class: "fw-bold text-dark mb-7" }, "Search Preferences", -1)),
              _cache[20] || (_cache[20] = _createElementVNode("div", { class: "pb-4 border-bottom" }, [
                _createElementVNode("label", { class: "form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack" }, [
                  _createElementVNode("span", { class: "form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2" }, " Projects "),
                  _createElementVNode("input", {
                    class: "form-check-input",
                    type: "checkbox",
                    value: "1",
                    checked: "checked"
                  })
                ])
              ], -1)),
              _cache[21] || (_cache[21] = _createElementVNode("div", { class: "py-4 border-bottom" }, [
                _createElementVNode("label", { class: "form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack" }, [
                  _createElementVNode("span", { class: "form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2" }, " Targets "),
                  _createElementVNode("input", {
                    class: "form-check-input",
                    type: "checkbox",
                    value: "1",
                    checked: "checked"
                  })
                ])
              ], -1)),
              _cache[22] || (_cache[22] = _createElementVNode("div", { class: "py-4 border-bottom" }, [
                _createElementVNode("label", { class: "form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack" }, [
                  _createElementVNode("span", { class: "form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2" }, " Affiliate Programs "),
                  _createElementVNode("input", {
                    class: "form-check-input",
                    type: "checkbox",
                    value: "1"
                  })
                ])
              ], -1)),
              _cache[23] || (_cache[23] = _createElementVNode("div", { class: "py-4 border-bottom" }, [
                _createElementVNode("label", { class: "form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack" }, [
                  _createElementVNode("span", { class: "form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2" }, " Referrals "),
                  _createElementVNode("input", {
                    class: "form-check-input",
                    type: "checkbox",
                    value: "1",
                    checked: "checked"
                  })
                ])
              ], -1)),
              _cache[24] || (_cache[24] = _createElementVNode("div", { class: "py-4 border-bottom" }, [
                _createElementVNode("label", { class: "form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack" }, [
                  _createElementVNode("span", { class: "form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2" }, " Users "),
                  _createElementVNode("input", {
                    class: "form-check-input",
                    type: "checkbox",
                    value: "1"
                  })
                ])
              ], -1)),
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("div", {
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.state = 'main')),
                  class: "btn btn-sm btn-white fw-bolder btn-active-light-primary me-2"
                }, " Cancel "),
                _cache[18] || (_cache[18] = _createElementVNode("button", { class: "btn btn-sm fw-bolder btn-primary" }, " Save Changes ", -1))
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}