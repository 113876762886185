import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "footer py-4 d-flex flex-lg-column",
  id: "kt_footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["d-flex flex-column flex-md-row align-items-center justify-content-between", {
        'container-fluid': _ctx.footerWidthFluid,
        'container-xxl': !_ctx.footerWidthFluid,
      }])
    }, _cache[0] || (_cache[0] = [
      _createStaticVNode("<div class=\"text-dark order-2 order-md-1\"><span class=\"text-muted fw-bold me-1\">2021©</span><a href=\"https://keenthemes.com/\" target=\"_blank\" class=\"text-gray-800 text-hover-primary\">Keenthemes</a></div><ul class=\"menu menu-gray-600 menu-hover-primary fw-bold order-1\"><li class=\"menu-item\"><a href=\"#\" target=\"_blank\" class=\"menu-link px-2\">About</a></li><li class=\"menu-item\"><a href=\"#\" target=\"_blank\" class=\"menu-link px-2\">Support</a></li><li class=\"menu-item\"><a href=\"#\" target=\"_blank\" class=\"menu-link px-2\">Purchase</a></li></ul>", 2)
    ]), 2)
  ]))
}