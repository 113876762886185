import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-items-stretch flex-shrink-0" }
const _hoisted_2 = { class: "d-flex align-items-stretch ms-1 ms-lg-3" }
const _hoisted_3 = { class: "d-flex align-items-center ms-1 ms-lg-3" }
const _hoisted_4 = {
  class: "btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px",
  id: "kt_activities_toggle"
}
const _hoisted_5 = { class: "svg-icon svg-icon-1" }
const _hoisted_6 = { class: "d-flex align-items-center ms-1 ms-lg-3" }
const _hoisted_7 = {
  class: "btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-attach": "parent",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "bottom"
}
const _hoisted_8 = { class: "svg-icon svg-icon-1" }
const _hoisted_9 = { class: "d-flex align-items-center ms-1 ms-lg-3" }
const _hoisted_10 = {
  class: "btn btn-icon btn-active-light-primary position-relative w-30px h-30px w-md-40px h-md-40px",
  id: "kt_drawer_chat_toggle"
}
const _hoisted_11 = { class: "svg-icon svg-icon-1" }
const _hoisted_12 = { class: "d-flex align-items-center ms-1 ms-lg-3" }
const _hoisted_13 = {
  class: "btn btn-icon btn-active-light-primary position-relative w-30px h-30px w-md-40px h-md-40px",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-attach": "parent",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "bottom"
}
const _hoisted_14 = { class: "svg-icon svg-icon-1" }
const _hoisted_15 = {
  class: "d-flex align-items-center ms-1 ms-lg-3",
  id: "kt_header_user_menu_toggle"
}
const _hoisted_16 = {
  class: "d-flex align-items-center d-lg-none ms-2 me-n3",
  title: "Show header menu"
}
const _hoisted_17 = {
  class: "btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px",
  id: "kt_header_menu_mobile_toggle"
}
const _hoisted_18 = { class: "svg-icon svg-icon-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTSearch = _resolveComponent("KTSearch")!
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_KTQuickLinksMenu = _resolveComponent("KTQuickLinksMenu")!
  const _component_KTNotificationsMenu = _resolveComponent("KTNotificationsMenu")!
  const _component_KTUserMenu = _resolveComponent("KTUserMenu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_KTSearch)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("span", _hoisted_5, [
          _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen032.svg" })
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("span", _hoisted_8, [
          _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen025.svg" })
        ])
      ]),
      _createVNode(_component_KTQuickLinksMenu)
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("span", _hoisted_11, [
          _createVNode(_component_inline_svg, { src: "media/icons/duotune/communication/com012.svg" })
        ]),
        _cache[0] || (_cache[0] = _createElementVNode("span", { class: "bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink" }, null, -1))
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("span", _hoisted_14, [
          _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen022.svg" })
        ])
      ]),
      _createVNode(_component_KTNotificationsMenu)
    ]),
    _createElementVNode("div", _hoisted_15, [
      _cache[1] || (_cache[1] = _createElementVNode("div", {
        class: "cursor-pointer symbol symbol-30px symbol-md-40px",
        "data-kt-menu-trigger": "click",
        "data-kt-menu-attach": "parent",
        "data-kt-menu-placement": "bottom-end",
        "data-kt-menu-flip": "bottom"
      }, [
        _createElementVNode("img", {
          src: "media/avatars/300-1.jpg",
          alt: "metronic"
        })
      ], -1)),
      _createVNode(_component_KTUserMenu)
    ]),
    _createElementVNode("div", _hoisted_16, [
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("span", _hoisted_18, [
          _createVNode(_component_inline_svg, { src: "media/icons/duotune/text/txt001.svg" })
        ])
      ])
    ])
  ]))
}