import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "menu menu-sub menu-sub-dropdown menu-column w-250px w-lg-325px",
  "data-kt-menu": "true"
}
const _hoisted_2 = { class: "row g-0" }
const _hoisted_3 = { class: "col-6" }
const _hoisted_4 = {
  href: "#",
  class: "d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom"
}
const _hoisted_5 = { class: "svg-icon svg-icon-3x svg-icon-success mb-2" }
const _hoisted_6 = { class: "col-6" }
const _hoisted_7 = {
  href: "#",
  class: "d-flex flex-column flex-center h-100 p-6 bg-hover-light border-bottom"
}
const _hoisted_8 = { class: "svg-icon svg-icon-3x svg-icon-success mb-2" }
const _hoisted_9 = { class: "col-6" }
const _hoisted_10 = {
  href: "#",
  class: "d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end"
}
const _hoisted_11 = { class: "svg-icon svg-icon-3x svg-icon-success mb-2" }
const _hoisted_12 = { class: "col-6" }
const _hoisted_13 = {
  href: "#",
  class: "d-flex flex-column flex-center h-100 p-6 bg-hover-light"
}
const _hoisted_14 = { class: "svg-icon svg-icon-3x svg-icon-success mb-2" }
const _hoisted_15 = { class: "py-2 text-center border-top" }
const _hoisted_16 = {
  href: "#",
  class: "btn btn-color-gray-600 btn-active-color-primary"
}
const _hoisted_17 = { class: "svg-icon svg-icon-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[9] || (_cache[9] = _createElementVNode("div", {
      class: "d-flex flex-column flex-center bgi-no-repeat rounded-top px-9 py-10",
      style: {"background-image":"url('media/misc/pattern-1.jpg')"}
    }, [
      _createElementVNode("h3", { class: "text-white fw-bold mb-3" }, "Quick Links"),
      _createElementVNode("span", { class: "badge bg-success py-2 px-3" }, "25 pending tasks")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("a", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/finance/fin009.svg" })
          ]),
          _cache[0] || (_cache[0] = _createElementVNode("span", { class: "fs-5 fw-bold text-gray-800 mb-0" }, "Accounting", -1)),
          _cache[1] || (_cache[1] = _createElementVNode("span", { class: "fs-7 text-gray-400" }, "eCommerce", -1))
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("a", _hoisted_7, [
          _createElementVNode("span", _hoisted_8, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/communication/com010.svg" })
          ]),
          _cache[2] || (_cache[2] = _createElementVNode("span", { class: "fs-5 fw-bold text-gray-800 mb-0" }, "Administration", -1)),
          _cache[3] || (_cache[3] = _createElementVNode("span", { class: "fs-7 text-gray-400" }, "Console", -1))
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("a", _hoisted_10, [
          _createElementVNode("span", _hoisted_11, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/abstract/abs042.svg" })
          ]),
          _cache[4] || (_cache[4] = _createElementVNode("span", { class: "fs-5 fw-bold text-gray-800 mb-0" }, "Projects", -1)),
          _cache[5] || (_cache[5] = _createElementVNode("span", { class: "fs-7 text-gray-400" }, "Pending Tasks", -1))
        ])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("a", _hoisted_13, [
          _createElementVNode("span", _hoisted_14, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/finance/fin006.svg" })
          ]),
          _cache[6] || (_cache[6] = _createElementVNode("span", { class: "fs-5 fw-bold text-gray-800 mb-0" }, "Customers", -1)),
          _cache[7] || (_cache[7] = _createElementVNode("span", { class: "fs-7 text-gray-400" }, "Latest cases", -1))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_15, [
      _createElementVNode("a", _hoisted_16, [
        _cache[8] || (_cache[8] = _createTextVNode(" View All ")),
        _createElementVNode("span", _hoisted_17, [
          _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr064.svg" })
        ])
      ])
    ])
  ]))
}