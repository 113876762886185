import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  class: "menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px",
  "data-kt-menu": "true"
}
const _hoisted_2 = { class: "tab-content" }
const _hoisted_3 = {
  class: "tab-pane fade",
  id: "kt_topbar_notifications_1",
  role: "tabpanel"
}
const _hoisted_4 = { class: "scroll-y mh-325px my-5 px-8" }
const _hoisted_5 = { class: "d-flex align-items-center" }
const _hoisted_6 = { class: "symbol symbol-35px me-4" }
const _hoisted_7 = { class: "mb-0 me-2" }
const _hoisted_8 = {
  href: "#",
  class: "fs-6 text-gray-800 text-hover-primary fw-bolder"
}
const _hoisted_9 = { class: "text-gray-400 fs-7" }
const _hoisted_10 = { class: "badge badge-light fs-8" }
const _hoisted_11 = { class: "py-3 text-center border-top" }
const _hoisted_12 = {
  href: "#",
  class: "btn btn-color-gray-600 btn-active-color-primary"
}
const _hoisted_13 = { class: "svg-icon svg-icon-5" }
const _hoisted_14 = {
  class: "tab-pane fade show active",
  id: "kt_topbar_notifications_2",
  role: "tabpanel"
}
const _hoisted_15 = { class: "d-flex flex-column px-9" }
const _hoisted_16 = ["src"]
const _hoisted_17 = {
  class: "tab-pane fade",
  id: "kt_topbar_notifications_3",
  role: "tabpanel"
}
const _hoisted_18 = { class: "scroll-y mh-325px my-5 px-8" }
const _hoisted_19 = { class: "d-flex align-items-center me-2" }
const _hoisted_20 = {
  href: "#",
  class: "text-gray-800 text-hover-primary fw-bold"
}
const _hoisted_21 = { class: "badge badge-light fs-8" }
const _hoisted_22 = { class: "py-3 text-center border-top" }
const _hoisted_23 = {
  href: "#",
  class: "btn btn-color-gray-600 btn-active-color-primary"
}
const _hoisted_24 = { class: "svg-icon-svg-icon-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[3] || (_cache[3] = _createStaticVNode("<div class=\"d-flex flex-column bgi-no-repeat rounded-top\" style=\"background-image:url(&#39;media/misc/pattern-1.jpg&#39;);\"><h3 class=\"text-white fw-bold px-9 mt-10 mb-6\"> Notifications <span class=\"fs-8 opacity-75 ps-3\">24 reports</span></h3><ul class=\"nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold px-9\"><li class=\"nav-item\"><a class=\"nav-link text-white opacity-75 opacity-state-100 pb-4\" data-bs-toggle=\"tab\" href=\"#kt_topbar_notifications_1\">Alerts</a></li><li class=\"nav-item\"><a class=\"nav-link text-white opacity-75 opacity-state-100 pb-4 active\" data-bs-toggle=\"tab\" href=\"#kt_topbar_notifications_2\">Updates</a></li><li class=\"nav-item\"><a class=\"nav-link text-white opacity-75 opacity-state-100 pb-4\" data-bs-toggle=\"tab\" href=\"#kt_topbar_notifications_3\">Logs</a></li></ul></div>", 1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data1, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "d-flex flex-stack py-4"
            }, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("span", {
                    class: _normalizeClass([`bg-light-${item.state}`, "symbol-label"])
                  }, [
                    _createElementVNode("span", {
                      class: _normalizeClass([`svg-icon-${item.state}`, "svg-icon svg-icon-2"])
                    }, [
                      _createVNode(_component_inline_svg, {
                        src: item.icon
                      }, null, 8, ["src"])
                    ], 2)
                  ], 2)
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("a", _hoisted_8, _toDisplayString(item.title), 1),
                  _createElementVNode("div", _hoisted_9, _toDisplayString(item.description), 1)
                ])
              ]),
              _createElementVNode("span", _hoisted_10, _toDisplayString(item.time), 1)
            ]))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("a", _hoisted_12, [
            _cache[0] || (_cache[0] = _createTextVNode(" View All ")),
            _createElementVNode("span", _hoisted_13, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr064.svg" })
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", _hoisted_15, [
          _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"pt-10 pb-0\"><h3 class=\"text-dark text-center fw-bolder\">Get Pro Access</h3><div class=\"text-center text-gray-600 fw-bold pt-1\"> Outlines keep you honest. They stoping you from amazing poorly about drive </div><div class=\"text-center mt-5 mb-9\"><a href=\"#\" class=\"btn btn-sm btn-primary px-6\" data-bs-toggle=\"modal\" data-bs-target=\"#kt_modal_upgrade_plan\">Upgrade</a></div></div>", 1)),
          _createElementVNode("img", {
            class: "mw-100 mh-200px",
            alt: "metronic",
            src: _ctx.getIllustrationsPath('1.png')
          }, null, 8, _hoisted_16)
        ])
      ]),
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("div", _hoisted_18, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data2, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "d-flex flex-stack py-4"
            }, [
              _createElementVNode("div", _hoisted_19, [
                _createElementVNode("span", {
                  class: _normalizeClass(["w-70px badge me-4", `badge-light-${item.state}`])
                }, _toDisplayString(item.code), 3),
                _createElementVNode("a", _hoisted_20, _toDisplayString(item.message), 1)
              ]),
              _createElementVNode("span", _hoisted_21, _toDisplayString(item.time), 1)
            ]))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_22, [
          _createElementVNode("a", _hoisted_23, [
            _cache[2] || (_cache[2] = _createTextVNode(" View All ")),
            _createElementVNode("span", _hoisted_24, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr064.svg" })
            ])
          ])
        ])
      ])
    ])
  ]))
}