import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: "toolbar",
  id: "kt_toolbar"
}
const _hoisted_2 = {
  "data-kt-swapper": "true",
  "data-kt-swapper-mode": "prepend",
  "data-kt-swapper-parent": "{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}",
  class: "page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
}
const _hoisted_3 = { class: "d-flex align-items-center text-dark fw-bolder my-1 fs-3" }
const _hoisted_4 = {
  key: 0,
  class: "h-20px border-gray-200 border-start mx-4"
}
const _hoisted_5 = {
  key: 1,
  class: "breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1"
}
const _hoisted_6 = { class: "breadcrumb-item pe-3" }
const _hoisted_7 = { class: "breadcrumb-item text-muted" }
const _hoisted_8 = { class: "breadcrumb-item pe-3 text-dark" }
const _hoisted_9 = { class: "d-flex align-items-center py-1" }
const _hoisted_10 = { class: "me-4" }
const _hoisted_11 = {
  href: "#",
  class: "btn btn-sm btn-flex btn-light btn-active-primary fw-bolder",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "top-end"
}
const _hoisted_12 = { class: "svg-icon svg-icon-5 svg-icon-gray-500 me-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Dropdown1 = _resolveComponent("Dropdown1")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      id: "kt_toolbar_container",
      class: _normalizeClass([{
        'container-fluid': _ctx.toolbarWidthFluid,
        'container-xxl': !_ctx.toolbarWidthFluid,
      }, "d-flex flex-stack"])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.title), 1),
        (_ctx.breadcrumbs)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4))
          : _createCommentVNode("", true),
        (_ctx.breadcrumbs)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_5, [
              _createElementVNode("li", _hoisted_6, [
                _createVNode(_component_router_link, {
                  to: "/dashboard",
                  class: "text-muted text-hover-primary"
                }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createTextVNode(" Home ")
                  ])),
                  _: 1
                })
              ]),
              _cache[2] || (_cache[2] = _createElementVNode("li", { class: "breadcrumb-item" }, [
                _createElementVNode("span", { class: "bullet bg-gray-200 w-5px h-2px" })
              ], -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbs, (item, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                  _createElementVNode("li", _hoisted_7, _toDisplayString(item), 1),
                  _cache[1] || (_cache[1] = _createElementVNode("li", { class: "breadcrumb-item" }, [
                    _createElementVNode("span", { class: "bullet bg-gray-200 w-5px h-2px" })
                  ], -1))
                ], 64))
              }), 128)),
              _createElementVNode("li", _hoisted_8, _toDisplayString(_ctx.title), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("a", _hoisted_11, [
            _createElementVNode("span", _hoisted_12, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen031.svg" })
            ]),
            _cache[3] || (_cache[3] = _createTextVNode(" Filter "))
          ]),
          _createVNode(_component_Dropdown1)
        ]),
        _cache[4] || (_cache[4] = _createElementVNode("a", {
          href: "#",
          class: "btn btn-sm btn-primary",
          "data-bs-toggle": "modal",
          "data-bs-target": "#kt_modal_create_app",
          id: "kt_toolbar_primary_button"
        }, " Create ", -1))
      ])
    ], 2)
  ]))
}