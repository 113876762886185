import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "kt_help",
  class: "bg-body",
  "data-kt-drawer": "true",
  "data-kt-drawer-name": "help",
  "data-kt-drawer-activate": "true",
  "data-kt-drawer-overlay": "true",
  "data-kt-drawer-width": "{default:'350px', 'md': '525px'}",
  "data-kt-drawer-direction": "end",
  "data-kt-drawer-toggle": "#kt_help_toggle",
  "data-kt-drawer-close": "#kt_help_close"
}
const _hoisted_2 = { class: "card shadow-none rounded-0 w-100" }
const _hoisted_3 = {
  class: "card-header",
  id: "kt_help_header"
}
const _hoisted_4 = { class: "card-toolbar" }
const _hoisted_5 = {
  type: "button",
  class: "btn btn-sm btn-icon explore-btn-dismiss me-n5",
  id: "kt_help_close"
}
const _hoisted_6 = { class: "svg-icon svg-icon-2" }
const _hoisted_7 = {
  class: "card-body",
  id: "kt_help_body"
}
const _hoisted_8 = {
  id: "kt_help_scroll",
  class: "hover-scroll-overlay-y",
  "data-kt-scroll": "true",
  "data-kt-scroll-height": "auto",
  "data-kt-scroll-wrappers": "#kt_help_body",
  "data-kt-scroll-dependencies": "#kt_help_header",
  "data-kt-scroll-offset": "5px"
}
const _hoisted_9 = { class: "d-flex align-items-center mb-7" }
const _hoisted_10 = { class: "d-flex flex-center w-50px h-50px w-lg-75px h-lg-75px flex-shrink-0 rounded bg-light-warning" }
const _hoisted_11 = { class: "svg-icon svg-icon-warning svg-icon-2x svg-icon-lg-3x" }
const _hoisted_12 = { class: "d-flex flex-stack flex-grow-1 ms-4 ms-lg-6" }
const _hoisted_13 = { class: "svg-icon svg-icon-gray-400 svg-icon-2" }
const _hoisted_14 = { class: "d-flex align-items-center mb-7" }
const _hoisted_15 = { class: "d-flex flex-center w-50px h-50px w-lg-75px h-lg-75px flex-shrink-0 rounded bg-light-primary" }
const _hoisted_16 = { class: "svg-icon svg-icon-primary svg-icon-2x svg-icon-lg-3x" }
const _hoisted_17 = { class: "d-flex flex-stack flex-grow-1 ms-4 ms-lg-6" }
const _hoisted_18 = { class: "svg-icon svg-icon-gray-400 svg-icon-2" }
const _hoisted_19 = { class: "d-flex align-items-center mb-7" }
const _hoisted_20 = { class: "d-flex flex-center w-50px h-50px w-lg-75px h-lg-75px flex-shrink-0 rounded bg-light-info" }
const _hoisted_21 = { class: "svg-icon svg-icon-info svg-icon-2x svg-icon-lg-3x" }
const _hoisted_22 = { class: "d-flex flex-stack flex-grow-1 ms-4 ms-lg-6" }
const _hoisted_23 = { class: "d-flex flex-column me-2 me-lg-5" }
const _hoisted_24 = { class: "d-flex align-items-center mb-7" }
const _hoisted_25 = { class: "d-flex flex-center w-50px h-50px w-lg-75px h-lg-75px flex-shrink-0 rounded bg-light-danger" }
const _hoisted_26 = { class: "svg-icon svg-icon-danger svg-icon-2x svg-icon-lg-3x" }
const _hoisted_27 = { class: "d-flex flex-stack flex-grow-1 ms-4 ms-lg-6" }
const _hoisted_28 = { class: "svg-icon svg-icon-gray-400 svg-icon-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[0] || (_cache[0] = _createElementVNode("h5", { class: "card-title fw-bold text-gray-600" }, "Learn & Get Inspired", -1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("button", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr061.svg" })
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _cache[7] || (_cache[7] = _createStaticVNode("<div class=\"rounded border border-dashed border-gray-300 p-6 p-lg-8 mb-10\"><h2 class=\"fw-bolder mb-5\"> Support at <a href=\"https://devs.keenthemes.com\" class=\"\">devs.keenthemes.com</a></h2><div class=\"fs-5 fw-bold mb-5\"><span class=\"text-gray-500\">Join our developers community to find answer to your question and help others.</span><a class=\"explore-link d-none\" href=\"https://keenthemes.com/licensing\">FAQs</a></div><a href=\"https://devs.keenthemes.com\" class=\"btn btn-lg explore-btn-primary w-100\">Get Support</a></div>", 1)),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("span", _hoisted_11, [
                _createVNode(_component_inline_svg, { src: "media/icons/duotune/abstract/abs027.svg" })
              ])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _cache[1] || (_cache[1] = _createElementVNode("div", { class: "d-flex flex-column me-2 me-lg-5" }, [
                _createElementVNode("a", {
                  href: "https://preview.keenthemes.com/metronic8/vue/docs/#/build",
                  class: "text-dark text-hover-primary fw-bolder fs-6 fs-lg-4 mb-1"
                }, "Documentation & Videos"),
                _createElementVNode("div", { class: "text-muted fw-bold fs-7 fs-lg-6" }, " From guides and video tutorials, to live demos and code examples to get started. ")
              ], -1)),
              _createElementVNode("span", _hoisted_13, [
                _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr064.svg" })
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("span", _hoisted_16, [
                _createVNode(_component_inline_svg, { src: "media/icons/duotune/ecommerce/ecm007.svg" })
              ])
            ]),
            _createElementVNode("div", _hoisted_17, [
              _cache[2] || (_cache[2] = _createElementVNode("div", { class: "d-flex flex-column me-2 me-lg-5" }, [
                _createElementVNode("a", {
                  href: "https://preview.keenthemes.com/metronic8/vue/docs/#/utilities",
                  class: "text-dark text-hover-primary fw-bolder fs-6 fs-lg-4 mb-1"
                }, "Plugins & Components"),
                _createElementVNode("div", { class: "text-muted fw-bold fs-7 fs-lg-6" }, " Check out our 300+ in-house components and customized 3rd-party plugins. ")
              ], -1)),
              _createElementVNode("span", _hoisted_18, [
                _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr064.svg" })
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("span", _hoisted_21, [
                _createVNode(_component_inline_svg, { src: "media/icons/duotune/art/art006.svg" })
              ])
            ]),
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("div", _hoisted_23, [
                _createVNode(_component_router_link, {
                  to: "/builder",
                  class: "text-dark text-hover-primary fw-bolder fs-6 fs-lg-4 mb-1"
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode("Layout Builder")
                  ])),
                  _: 1
                }),
                _cache[4] || (_cache[4] = _createElementVNode("div", { class: "text-muted fw-bold fs-7 fs-lg-6" }, " Dynamically modify and preview layout ", -1))
              ]),
              _cache[5] || (_cache[5] = _createElementVNode("span", { class: "svg-icon svg-icon-gray-400 svg-icon-2" }, [
                _createElementVNode("svg", {
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "24",
                  height: "24",
                  viewBox: "0 0 24 24",
                  fill: "none"
                }, [
                  _createElementVNode("rect", {
                    opacity: "0.5",
                    x: "18",
                    y: "13",
                    width: "13",
                    height: "2",
                    rx: "1",
                    transform: "rotate(-180 18 13)",
                    fill: "black"
                  }),
                  _createElementVNode("path", {
                    d: "M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z",
                    fill: "black"
                  })
                ])
              ], -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_24, [
            _createElementVNode("div", _hoisted_25, [
              _createElementVNode("span", _hoisted_26, [
                _createVNode(_component_inline_svg, { src: "media/icons/duotune/electronics/elc009.svg" })
              ])
            ]),
            _createElementVNode("div", _hoisted_27, [
              _cache[6] || (_cache[6] = _createElementVNode("div", { class: "d-flex flex-column me-2 me-lg-5" }, [
                _createElementVNode("a", {
                  href: "https://preview.keenthemes.com/metronic8/vue/docs/#/changelog",
                  class: "text-dark text-hover-primary fw-bolder fs-6 fs-lg-4 mb-1"
                }, "What's New"),
                _createElementVNode("div", { class: "text-muted fw-bold fs-7 fs-lg-6" }, " Latest features and improvements added with our users feedback in mind. ")
              ], -1)),
              _createElementVNode("span", _hoisted_28, [
                _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr064.svg" })
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}