import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  id: "kt_drawer_chat",
  class: "bg-white",
  "data-kt-drawer": "true",
  "data-kt-drawer-name": "chat",
  "data-kt-drawer-activate": "true",
  "data-kt-drawer-overlay": "true",
  "data-kt-drawer-width": "{default:'300px', 'md': '500px'}",
  "data-kt-drawer-direction": "end",
  "data-kt-drawer-toggle": "#kt_drawer_chat_toggle",
  "data-kt-drawer-close": "#kt_drawer_chat_close"
}
const _hoisted_2 = {
  class: "card w-100",
  id: "kt_drawer_chat_messenger"
}
const _hoisted_3 = {
  class: "card-header pe-5",
  id: "kt_drawer_chat_messenger_header"
}
const _hoisted_4 = { class: "card-toolbar" }
const _hoisted_5 = { class: "me-2" }
const _hoisted_6 = {
  class: "btn btn-sm btn-icon btn-active-icon-primary",
  id: "kt_drawer_chat_close"
}
const _hoisted_7 = { class: "svg-icon svg-icon-2x" }
const _hoisted_8 = {
  class: "card-body",
  id: "kt_drawer_chat_messenger_body"
}
const _hoisted_9 = {
  class: "scroll-y me-n5 pe-5",
  ref: "messagesRef",
  "data-kt-element": "messages",
  "data-kt-scroll": "true",
  "data-kt-scroll-activate": "true",
  "data-kt-scroll-height": "auto",
  "data-kt-scroll-dependencies": "#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer",
  "data-kt-scroll-wrappers": "#kt_drawer_chat_messenger_body",
  "data-kt-scroll-offset": "0px"
}
const _hoisted_10 = {
  class: "card-footer pt-4",
  id: "kt_drawer_chat_messenger_footer"
}
const _hoisted_11 = { class: "d-flex flex-stack" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown4 = _resolveComponent("Dropdown4")!
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_MessageIn = _resolveComponent("MessageIn")!
  const _component_MessageOut = _resolveComponent("MessageOut")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[4] || (_cache[4] = _createStaticVNode("<div class=\"card-title\"><div class=\"d-flex justify-content-center flex-column me-3\"><a href=\"#\" class=\"fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1\">Brian Cox</a><div class=\"mb-0 lh-1\"><span class=\"badge badge-success badge-circle w-10px h-10px me-1\"></span><span class=\"fs-7 fw-bold text-gray-400\">Active</span></div></div></div>", 1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[3] || (_cache[3] = _createElementVNode("button", {
              class: "btn btn-sm btn-icon btn-active-icon-primary",
              "data-kt-menu-trigger": "click",
              "data-kt-menu-placement": "bottom-end",
              "data-kt-menu-flip": "top-end"
            }, [
              _createElementVNode("i", { class: "bi bi-three-dots fs-3" })
            ], -1)),
            _createVNode(_component_Dropdown4)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr061.svg" })
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (item, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
              (item.type === 'in')
                ? (_openBlock(), _createBlock(_component_MessageIn, {
                    key: 0,
                    ref_for: true,
                    ref: "messagesInRef",
                    name: item.name,
                    image: item.image,
                    time: item.time,
                    text: item.text
                  }, null, 8, ["name", "image", "time", "text"]))
                : _createCommentVNode("", true),
              (item.type === 'out')
                ? (_openBlock(), _createBlock(_component_MessageOut, {
                    key: 1,
                    ref_for: true,
                    ref: "messagesOutRef",
                    image: item.image,
                    time: item.time,
                    text: item.text
                  }, null, 8, ["image", "time", "text"]))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ], 512)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _withDirectives(_createElementVNode("input", {
          class: "form-control form-control-flush mb-3",
          "data-kt-element": "input",
          placeholder: "Type a message",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newMessageText) = $event)),
          onKeydown: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.addNewMessage && _ctx.addNewMessage(...args)), ["enter"]))
        }, null, 544), [
          [_vModelText, _ctx.newMessageText]
        ]),
        _createElementVNode("div", _hoisted_11, [
          _cache[5] || (_cache[5] = _createStaticVNode("<div class=\"d-flex align-items-center me-2\"><button class=\"btn btn-sm btn-icon btn-active-light-primary me-1\" type=\"button\" data-bs-toggle=\"tooltip\" title=\"Coming soon\"><i class=\"bi bi-paperclip fs-3\"></i></button><button class=\"btn btn-sm btn-icon btn-active-light-primary me-1\" type=\"button\" data-bs-toggle=\"tooltip\" title=\"Coming soon\"><i class=\"bi bi-upload fs-3\"></i></button></div>", 1)),
          _createElementVNode("button", {
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.addNewMessage && _ctx.addNewMessage(...args))),
            class: "btn btn-primary",
            type: "button",
            "data-kt-element": "send"
          }, " Send ")
        ])
      ])
    ])
  ]))
}